import React from "react";
import { withRouter } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from "@azure/msal-react";
import { sendMsGraphMail } from "./graph";
import { loginRequest, sendMailRequest } from "./authConfig";
import Routes from "./Routes";
import "./App.css";
import MainMenu from "./components/MainMenu";
import Login from "./containers/Login";
import { useGetUser } from "./components/useGetUser";

const App = (props) => {
  const AppContent = () => {
    const { instance, accounts } = useMsal();
    const userId = `${accounts[0].localAccountId}`;
    const name = accounts[0].name;
    const email = accounts[0].username;

    const sendEmail = (message, callback = null) => {
      instance
        .acquireTokenSilent({
          ...sendMailRequest,
          account: accounts[0]
        })
        .then((response) => {
          sendMsGraphMail(response.accessToken, message).then((response) => {
            if (callback) callback(response);
          });
        });
    };

    const getToken = async (callback = null) => {
      const res = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      });

      if (callback) {
        callback(res);
      } else {
        return res;
      }
    };

    let user = {};
    let auth = {};

    const creds = useGetUser(userId, getToken);
    const isCreds = creds !== null;
    user = creds?.user ?? null;
    auth = creds?.auth ?? null;

    if (isCreds && !user) {
      const guest = {
        id: userId,
        name,
        email,
        role: "guest",
        prefs: {}
      };

      user = { ...guest };
    }

    return (
      isCreds && (
        <div>
          <MainMenu location={props.location} user={user} />
          <Routes appProps={{ user, auth, getToken, sendEmail }} />
        </div>
      )
    );
  };

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <AppContent />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default withRouter(App);
