import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
const callMsGraph = async (accessToken) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

/**
 * Attaches a given access token to a MS Graph API call. Sends mail on user's behalf
 * @param accessToken
 */
const sendMsGraphMail = async (accessToken, message) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(message)
  };

  return fetch(graphConfig.graphMeSendMailEndpoint, options)
    .then((response) => response)
    .catch((error) => console.log(error));
};

export { callMsGraph, sendMsGraphMail };
