import React from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";

import "./Developer.css";

const Developer = (props) => {
  const { user } = props;

  // const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

  return (
    <div className="Developer">
      <Card>
        <CardHeader tag="h2">Developer</CardHeader>
        <CardBody>
          <CardTitle>{user.name}, you are logged on as developer.</CardTitle>
          {/* <Json data={user} /> */}
        </CardBody>
      </Card>
    </div>
  );
};

export default Developer;
