import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Progress,
  Spinner
} from "reactstrap";
import { bulkInsert } from "../services/bulkServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "./SeedCollection.css";

const CALL_DELAY = 50;
const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const getTimestamp = () => new Date().getTime();
const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const SeedCollection = (props) => {
  const { user, auth } = props;

  const userInfo = {
    id: user.id,
    name: user.name,
    org: user.org,
    email: user.email
  };

  const [isLoading, setIsLoading] = useState(false);
  const [collection, setCollection] = useState("");
  const [dataDoc, setDataDoc] = useState("[{}]");

  const [transferProgress, setTransferProgress] = useState(0);
  const [transferTotalRecords, setTransferTotalRecords] = useState(0);
  const [isTransferring, setIsTransferring] = useState(false);

  const [insertError, setInsertError] = useState(null);

  const handleCollection = (coll) => {
    setCollection(coll);
  };

  const handleDataDoc = (doc) => {
    setDataDoc(doc);
  };

  const isValidJson = (str) => {
    let temp = null;

    try {
      temp = JSON.parse(str);

      return Array.isArray(temp) && temp.length;
    } catch (e) {
      return false;
    }
  };

  const validateForm = () => {
    const isCollection = collection !== "";
    const isData = dataDoc !== "";
    const isDataValid = isValidJson(dataDoc);

    return isCollection && isData && isDataValid;
  };

  const TransferProgressBar = () => {
    const isPlural = transferTotalRecords > 1;

    return (
      <Card body>
        <CardText className="text-center">
          {transferProgress} of {transferTotalRecords} record
          {isPlural ? "s" : ""} inserted
        </CardText>
        <Progress
          color="success"
          value={transferProgress}
          max={transferTotalRecords}
        />
      </Card>
    );
  };

  const ShowErrors = () => {
    return (
      <Card>
        <CardHeader className="card-header-danger">
          <div>
            Encountered error
            <Button
              color="danger"
              size="sm"
              onClick={() => setInsertError(null)}
              className="collapse-button btn-float-right"
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="overflow-500">
          <Json data={insertError} />
        </CardBody>
      </Card>
    );
  };

  const transformPayload = (payload) => {
    const docs = [];
    let temp = null;

    payload.forEach((doc) => {
      switch (collection) {
        case "CrewMembers":
          const tag = doc?.overrides?.tag ?? "";
          const supervisor = doc?.overrides?.supervisor ?? false;
          const treeTrim = doc?.overrides?.tt ?? false;
          const damageAssessment = false;
          const countryCode = doc?.smsReceivers?.countryCode ?? "1";
          const phoneNumber = doc?.smsReceivers?.phoneNumber ?? null;

          const crew = doc.crew
            ? Array.isArray(doc.crew)
              ? doc.crew
              : doc.crew.split(",")
            : [];
          const serviceCenter = doc.serviceCenter
            ? Array.isArray(doc.serviceCenter)
              ? doc.serviceCenter
              : doc.serviceCenter.split(",")
            : [];

          temp = {
            id: doc.userID,
            name: doc.name ?? "",
            email: doc.email ?? "",
            smsReceivers: {
              countryCode,
              phoneNumber
            },
            org: doc.company ?? "",
            crew,
            serviceCenter,
            options: {
              tag,
              supervisor,
              treeTrim,
              damageAssessment
            },
            auth: {
              MSSTS: {
                localAccountId: null,
                name: null,
                username: null
              }
            },
            active: true,
            createdBy: userInfo,
            createdAt: getTimestamp()
          };

          docs.push(temp);
          break;
        default:
          temp = {
            ...doc,
            createdAt: getTimestamp()
          };

          docs.push(temp);
      }
    });

    return docs;
  };

  const handleInsert = async () => {
    setIsLoading(true);
    setInsertError(null);

    const parsedDoc = JSON.parse(dataDoc);

    const docs = transformPayload(parsedDoc);

    setTransferTotalRecords(docs.length);
    setIsTransferring(true);

    for (let i = 0; i < docs.length; i++) {
      setTransferProgress(i + 1);

      const doc = {
        ...docs[i]
      };

      try {
        const payload = {
          collection,
          docs: [doc],
          confirm: true
        };

        const res = await bulkInsert(payload, auth.idToken);

        if (res.status !== 200) {
          setIsTransferring(false);
          setIsLoading(false);
          setInsertError({ item: i + 1, error: res.response });

          return false;
        }
      } catch (e) {
        setIsTransferring(false);
        setIsLoading(false);
        setInsertError({ item: i + 1, error: e.message });

        return false;
      }

      await delay(CALL_DELAY);
    }

    setIsTransferring(false);
    setIsLoading(false);
  };

  return (
    <div className="SeedCollection">
      <Card>
        <CardHeader tag="h5">Seed Collection</CardHeader>
        <CardBody>
          <Card>
            <CardHeader>Bulk Insert</CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <Label for="collection-select">Collection</Label>
                  <Input
                    type="select"
                    name="collection-select"
                    id="collection-select"
                    onChange={(e) => handleCollection(e.target.value)}
                    disabled={isLoading}
                  >
                    <option value="">Select collection from list</option>
                    <option value="Users">Users</option>
                    <option value="CrewMembers">Crew Members</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="batch-data">Data (JSON)</Label>
                  <Input
                    invalid={!isValidJson(dataDoc)}
                    type="textarea"
                    name="batch-data"
                    id="batch-data"
                    value={dataDoc}
                    onChange={(e) => handleDataDoc(e.target.value)}
                    disabled={isLoading}
                  />
                  <FormFeedback>
                    Invalid JSON array or array is empty
                  </FormFeedback>
                </FormGroup>
              </Form>
              {isTransferring && <TransferProgressBar />}
              {insertError && <ShowErrors />}
            </CardBody>
            <CardFooter>
              <Button
                outline
                color="primary"
                size="sm"
                onClick={() => handleInsert()}
                disabled={isLoading || !validateForm()}
              >
                Process batch {isLoading && <Spinner size="sm" color="dark" />}
              </Button>
            </CardFooter>
          </Card>
        </CardBody>
      </Card>
    </div>
  );
};

export default SeedCollection;
