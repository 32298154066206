import React from "react";
import ImportCrewMemberDataComp from "../components/ImportCrewMemberDataComp";
import "./ImportCrewMemberData.css";

const ImportCrewMemberData = (props) => {
  return (
    <div className="ImportCrewMemberData">
      <div className="container">
        <ImportCrewMemberDataComp {...props} />
      </div>
    </div>
  );
};

export default ImportCrewMemberData;
