import React from "react";
import { Route, Switch } from "react-router-dom";

// Containers
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import CrewMembers from "./containers/CrewMembers";
import Users from "./containers/Users";
import ImportCrewMemberData from "./containers/ImportCrewMemberData";
import DeleteCrewMemberData from "./containers/DeleteCrewMemberData";
import Seed from "./containers/Seed";
import Mail from "./containers/Mail";
import Auth from "./containers/Auth";
import SignalR from "./containers/SignalR";

// Authentication
import AuthenticatedRoute from "./components/AuthenticatedRoute";

const Routes = ({ appProps }) => {
  return (
    <Switch>
      <AuthenticatedRoute path="/" exact component={Home} appProps={appProps} />
      <AuthenticatedRoute
        path="/crew-members"
        exact
        component={CrewMembers}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/users"
        exact
        component={Users}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/import-crew-member-data"
        exact
        component={ImportCrewMemberData}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/delete-crew-member-data"
        exact
        component={DeleteCrewMemberData}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/seed"
        exact
        component={Seed}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/mail"
        exact
        component={Mail}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/auth-test"
        exact
        component={Auth}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/in-app-notify"
        exact
        component={SignalR}
        appProps={appProps}
      />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
