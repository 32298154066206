import React from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import "./Revoked.css";

const Revoked = () => {
  return (
    <div className="container">
      <div className="Revoked">
        <Card>
          <CardHeader tag="h2">Account Services</CardHeader>
          <CardBody>
            <CardTitle>
              You have no longer access to this application.
            </CardTitle>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Revoked;
