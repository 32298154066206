import React from "react";
import {
  CardText,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "./GetPagination.css";

const GetPagination = (props) => {
  const pageLinks = [];
  const { currentPage, pages } = props;
  const inc = 5;
  let start = currentPage - (currentPage % inc);

  if (currentPage % inc === 0) start = start - inc;

  for (let i = start; i <= start + inc - 1 && i < pages; i++) {
    const newIndex = i + 1;

    pageLinks.push(
      <PaginationItem key={i} active={currentPage === newIndex}>
        <PaginationLink onClick={() => props.nextPage(newIndex)}>
          {newIndex}
        </PaginationLink>
      </PaginationItem>
    );
  }

  return (
    <div className="GetPagination">
      <div className="pagination-wrapper">
        <CardText>
          Page {currentPage} of {pages}
        </CardText>
        {pages > 1 && (
          <Pagination size="sm">
            {currentPage > inc && (
              <PaginationItem>
                <PaginationLink
                  first
                  onClick={() => props.firstChange()}
                ></PaginationLink>
              </PaginationItem>
            )}
            {currentPage > 2 * inc && (
              <PaginationItem>
                <PaginationLink
                  previous
                  onClick={() => props.incChange(currentPage, inc, -1)}
                ></PaginationLink>
              </PaginationItem>
            )}
            {pageLinks}
            {inc < pages && pages - currentPage >= inc && (
              <PaginationItem>
                <PaginationLink
                  next
                  onClick={() => props.incChange(currentPage, inc, 1)}
                ></PaginationLink>
              </PaginationItem>
            )}
            {inc < pages && pages - currentPage >= inc && (
              <PaginationItem>
                <PaginationLink
                  last
                  onClick={() => props.lastChange(pages)}
                ></PaginationLink>
              </PaginationItem>
            )}
          </Pagination>
        )}
      </div>
    </div>
  );
};

export default GetPagination;
