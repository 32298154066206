import React, { useState } from "react";
import {
  Button,
  CardText,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import prettyBytes from "pretty-bytes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { getCrewMembers } from "../services/crewMemberService";
import "./ModalDownload.css";

const ModalDownload = (props) => {
  const {
    searchOption,
    searchOptionValue,
    currentPage,
    pageSize,
    totalDocs,
    handleClose,
    auth
  } = props;

  const isEven = totalDocs % pageSize === 0;
  const pages = Math.floor(totalDocs / pageSize) + (isEven ? 0 : 1);
  const defOption = pages > 1 ? "all" : "current";

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [option, setOption] = useState(defOption);
  const [rangeFrom, setRangeFrom] = useState(1);
  const [rangeTo, setRangeTo] = useState(1);
  const [hrefCsv, setHrefCsv] = useState(null);
  const [csvSize, setCsvSize] = useState(null);

  const toggleModal = () => {
    setHrefCsv(null);
    setCsvSize(null);
    setOpenModal(!openModal);
    handleClose(!openModal);
  };

  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const validateForm = () => {
    const isAll = searchOption === "all";

    if (isAll) return true;

    return searchOptionValue !== "";
  };

  const convertToCsv = (docs) => {
    const headers = [
      "id",
      "type",
      "name",
      // "email",
      "org",
      "crew",
      "serviceCenter",
      "active",
      "tag",
      "supervisor",
      "treeTrim",
      "damageAssessment",
      "countryCode",
      "phoneNumber"
    ];

    const rows = docs.map((row, i) => {
      const id = row.id;
      const type = row.type;
      const name = row.name;
      // const email = row.email ? row.email : "";
      const org = row.org;
      const crew = row.crew.join(",");
      const aor = row.serviceCenter.join(",");
      const active = row.active ? "Yes" : "No";
      const tag = row.options.tag;
      const supervisor = row.options.supervisor ? "Yes" : "No";
      const treeTrim = row.options.treeTrim ? "Yes" : "No";
      const damageAssessment = row.options.damageAssessment ? "Yes" : "No";
      const smsReceivers = row.smsReceivers;
      const countryCode = smsReceivers.countryCode;
      const phoneNumber = smsReceivers.phoneNumber
        ? smsReceivers.phoneNumber
        : "";

      const arr = [
        `"${id}"`,
        `"${type}"`,
        `"${name}"`,
        // `"${email}"`,
        `"${org}"`,
        `"${crew}"`,
        `"${aor}"`,
        `"${active}"`,
        `"${tag}"`,
        `"${supervisor}"`,
        `"${treeTrim}"`,
        `"${damageAssessment}"`,
        `"${countryCode}"`,
        `"${phoneNumber}"`
      ];

      return arr.join(",");
    });

    return [headers.join(","), ...rows].join("\n");
  };

  const handleDownload = async () => {
    let query = {};

    setIsLoading(true);

    if (searchOption !== "all") query[searchOption] = searchOptionValue;

    switch (option) {
      case "all":
        query.page = 1;
        query.count = pages * pageSize;
        break;
      case "current":
        query.page = currentPage;
        query.count = pageSize;
        break;
      case "range":
        query.page = parseInt(rangeFrom);
        query.count = (rangeTo - rangeFrom + 1) * pageSize;
        break;
      default:
      // Do nothing
    }

    try {
      const docs = await getCrewMembers(query, auth.idToken);

      const csvData = convertToCsv(docs);

      // Create URL for CSV data
      const fileCsv = new Blob([csvData], {
        type: "text/csv"
      });

      setHrefCsv(URL.createObjectURL(fileCsv));
      setCsvSize(fileCsv.size);
      setIsLoading(false);
    } catch (e) {}
  };

  const handleCancel = () => {
    toggleModal();
  };

  const handleOption = (option) => {
    setOption(option);
  };

  const handleRangeFrom = (page) => {
    setRangeFrom(page);
  };

  const handleRangeTo = (page) => {
    setRangeTo(page);
  };

  const newArray = (n, offset = 1) => {
    const x = [];
    for (let i = offset; i <= n; ++i) {
      x[i] = i;
    }
    return x;
  };

  return (
    <div className="ModalDownload">
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          Download Results (CSV)
        </ModalHeader>
        {!hrefCsv && (
          <ModalBody>
            <FormGroup tag="fieldset">
              <CardText>
                <b>Page selection</b>
              </CardText>
              {pages > 1 && (
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="page-selection"
                      value="all"
                      defaultChecked={true}
                      onChange={(e) => handleOption(e.target.value)}
                    />{" "}
                    All pages
                  </Label>
                </FormGroup>
              )}
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="page-selection"
                    value="current"
                    defaultChecked={pages === 1}
                    onChange={(e) => handleOption(e.target.value)}
                  />{" "}
                  Page #{currentPage} (current)
                </Label>
              </FormGroup>
              {pages > 2 && (
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="page-selection"
                      value="range"
                      onChange={(e) => handleOption(e.target.value)}
                    />{" "}
                    Select page range
                  </Label>
                </FormGroup>
              )}
              {option === "range" && pages > 2 && (
                <>
                  <FormGroup className="mt-05rem mb-0rem">
                    <Label for="from-range">From page:</Label>
                    <Input
                      type="select"
                      name="from-range"
                      id="from-range"
                      value={rangeFrom}
                      onChange={(e) => handleRangeFrom(e.target.value)}
                    >
                      {newArray(pages).map((p, i) => {
                        return (
                          <option key={i} value={p}>
                            {p}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                  <FormGroup className="mt-05rem mb-0rem">
                    <Label for="to-range">To page:</Label>
                    <Input
                      type="select"
                      name="to-range"
                      id="to-range"
                      value={rangeTo}
                      onChange={(e) => handleRangeTo(e.target.value)}
                    >
                      {newArray(pages, rangeFrom).map((p, i) => {
                        return (
                          <option key={i} value={p}>
                            {p}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </>
              )}
            </FormGroup>
          </ModalBody>
        )}
        {hrefCsv && (
          <ModalBody>
            <CardText>
              Click on the link below to download the CSV file
            </CardText>
            <CardText>
              <a href={hrefCsv} download="search-results.csv">
                <FontAwesomeIcon icon={faDownload} /> Search Results (
                {prettyBytes(csvSize ? csvSize : 0)})
              </a>
            </CardText>
          </ModalBody>
        )}
        <ModalFooter>
          <FormGroup>
            {!hrefCsv && (
              <div>
                <Button
                  className="mr-05rem"
                  color="primary"
                  size="sm"
                  disabled={!validateForm() || isLoading}
                  onClick={handleDownload}
                >
                  Prepare download
                </Button>{" "}
                <Button outline color="danger" size="sm" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            )}
            {hrefCsv && (
              <Button outline color="primary" size="sm" onClick={handleCancel}>
                Done
              </Button>
            )}
          </FormGroup>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export { ModalDownload };
