import axios from "axios";

const baseUrl = process.env.REACT_APP_APPREG_APIBASEURL;
const hostKey = process.env.REACT_APP_AZFUNCTIONS_HOSTKEY;

const getCollectionCount = async (query, token = null) => {
  const url = `${baseUrl}/api/getCollectionCount?code=${hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: query
  });

  return data;
};

export { getCollectionCount };
