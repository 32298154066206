import base64 from "base-64";

const EmailAddress = from => {
  return {
    emailAddress: {
      name: from.name || "",
      address: from.address
    }
  };
};

const Body = body => {
  return {
    contentType: body.contentType || "Text",
    content: body.content
  };
};

const Attachment = attachment => {
  return {
    "@odata.type": "#microsoft.graph.fileAttachment",
    name: attachment.name,
    contentType: attachment.contentType || "text/plain",
    contentBytes: base64.encode(attachment.content)
  };
};

const MailMessage = (
  from,
  toList,
  subject,
  body,
  attachmentList = [],
  ccList = [],
  bccList = [],
  saveToSentItems
) => {
  const toRecipients = toList.map(o => EmailAddress(o));
  const ccRecipients = ccList.map(o => EmailAddress(o));
  const bccRecipients = bccList.map(o => EmailAddress(o));
  const attachments = attachmentList.map(o => Attachment(o));

  const msg = {
    message: {
      from: EmailAddress(from),
      subject,
      body: Body(body),
      toRecipients,
      ccRecipients,
      bccRecipients,
      attachments
    },
    saveToSentItems: saveToSentItems ? "true" : "false"
  };

  return msg;
};

export { MailMessage };
