import axios from "axios";

const baseUrl = process.env.REACT_APP_APPREG_APIBASEURL;
const hostKey = process.env.REACT_APP_AZFUNCTIONS_HOSTKEY;

const signalRNegotiate = async (token = null) => {
  const url = `${baseUrl}/api/negotiate?code=${hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: null,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const getNotifications = async (userId, token = null) => {
  const url = `${baseUrl}/api/notificationsGet?code=${hostKey}`;

  const { data, status } = await axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: { userId }
  });

  return { status, response: data };
};

const readNotification = async (id, token = null) => {
  const url = `${baseUrl}/api/notificationUpdate?code=${hostKey}`;
  const { data, status } = await axios({
    method: "PUT",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: { id },
    data: {}
  });

  return { status, response: data };
};

export { signalRNegotiate, getNotifications, readNotification };
