const Access = {
  containers: {
    users: {
      roles: ["admin", "it-admin", "dev"]
    },
    "crew-members": {
      roles: ["admin", "it-admin", "dev"]
    },
    "import-crew-member-data": {
      roles: ["admin", "it-admin", "dev"]
    },
    "delete-crew-member-data": {
      roles: ["it-admin", "dev"]
    },
    seed: {
      roles: ["dev"]
    },
    mail: {
      roles: ["dev"]
    },
    "auth-test": {
      roles: ["dev"]
    },
    "in-app-notify": {
      roles: ["dev"]
    }
  },
  links: [
    {
      path: "/",
      label: "Home",
      roles: ["admin", "it-admin", "dev"]
    },
    {
      path: "/crew-members",
      label: "Crew Members",
      roles: ["admin", "it-admin", "dev"]
    },
    {
      label: "Admin",
      roles: ["admin", "it-admin", "dev"],
      links: [
        {
          path: "/users",
          label: "User Management",
          roles: ["admin", "it-admin", "dev"]
        },
        {
          path: "/import-crew-member-data",
          label: "Import Crew Member Data",
          roles: ["admin", "it-admin", "dev"]
        },
        {
          path: "/delete-crew-member-data",
          label: "Delete Crew Member Data",
          roles: ["it-admin", "dev"]
        }
      ]
    },
    {
      label: "Dev",
      roles: ["dev"],
      links: [
        {
          path: "/seed",
          label: "Seed Collection",
          roles: ["dev"]
        },
        {
          path: "/mail",
          label: "Mail Test",
          roles: ["dev"]
        },
        {
          path: "/auth-test",
          label: "Auth Test",
          roles: ["dev"]
        },
        {
          path: "/in-app-notify",
          label: "Signal R",
          roles: ["dev"]
        }
      ]
    }
  ]
};

const getContainerAccessRoles = (c) => {
  const container = c.replace("/", "");

  return Access.containers[container]
    ? Access.containers[container].roles
    : ["admin", "it-admin", "dev", "guest"];
};

const getLinks = () => {
  return Access.links;
};

export { getContainerAccessRoles, getLinks };
