import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Spinner
} from "reactstrap";
import { bulkGet } from "../services/bulkServices";
import "./AuthTest.css";

const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const AuthTest = (props) => {
  const { user, getToken } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [results, setResults] = useState(null);

  const getUserToken = async () => {
    setIsLoading(true);

    const token = await getToken();

    setResults(token);
    setToken(token.idToken);
    setIsLoading(false);
  };

  const queryUsers = async () => {
    const query = {
      collection: "CrewMembers",
      filter: {},
      project: {
        _id: 0
      },
      confirm: true
    };

    const res = await bulkGet(query, token);

    setResults(res);
  };

  return (
    <div className="AuthTest">
      <Card>
        <CardHeader tag="h5">Auth Test</CardHeader>
        <CardBody>
          <CardTitle>{user.name}, you are logged on as developer.</CardTitle>
          {!results && (
            <Button
              color="primary"
              className="mb-1rem"
              disabled={isLoading}
              onClick={getUserToken}
            >
              Request token {isLoading && <Spinner size="sm" color="primary" />}
            </Button>
          )}
          {results && (
            <Button
              color="primary"
              className="mb-1rem"
              disabled={isLoading}
              onClick={queryUsers}
            >
              Query Users {isLoading && <Spinner size="sm" color="primary" />}
            </Button>
          )}
          {results && (
            <Card>
              <CardHeader>Results</CardHeader>
              <CardBody>
                <Json data={results} />
              </CardBody>
            </Card>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default AuthTest;
