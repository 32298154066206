import React from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";

import "./ItAdmin.css";

const ItAdmin = (props) => {
  const { user } = props;

  // const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

  return (
    <div className="ItAdmin">
      <Card>
        <CardHeader tag="h2">IT Administrator</CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, you are logged on as IT administrator.
          </CardTitle>
          {/* <Json data={user} /> */}
        </CardBody>
      </Card>
    </div>
  );
};

export default ItAdmin;
