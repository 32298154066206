import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Input,
  Spinner
} from "reactstrap";
import {
  signalRNegotiate,
  getNotifications,
  readNotification
} from "../services/signalrServices";
import * as signalR from "@microsoft/signalr";
import "./SignalRTest.css";

const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const SignalRTest = (props) => {
  const { getToken } = props;

  const [token, setToken] = useState(null);
  const [connection, setConnection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [userLog, setUserLog] = useState([]);
  const [userId, setUserId] = useState("");

  const handleLog = (message) => {
    setUserLog((userLog) => [message, ...userLog]);
  };

  const handleRead = async (id) => {
    setIsLoading(true);

    // Find notification
    const index = userLog.findIndex((item) => item.id === id);

    const logCopy = [...userLog];

    // Update notification
    logCopy[index].read = true;

    // Update state
    setUserLog(logCopy);

    // Update notification in db
    await readNotification(id, token.idToken);

    setIsLoading(false);
  };

  const joinHub = async () => {
    setIsLoading(true);

    const token = await getToken();

    setToken(token);

    if (token) {
      const notificationsReq = await getNotifications(userId, token.idToken);

      if (notificationsReq.status === 200) {
        setUserLog(notificationsReq.response);
      }

      const negotiate = await signalRNegotiate(token.idToken);

      if (negotiate.status === 200) {
        try {
          const connectionUrl = negotiate.response.url;
          const accessToken = negotiate.response.accessToken;

          const conn = new signalR.HubConnectionBuilder()
            .withUrl(connectionUrl, { accessTokenFactory: () => accessToken })
            // .configureLogging(signalR.LogLevel.Trace)
            .withAutomaticReconnect()
            .build();

          conn.onclose(() => {
            setConnected(false);
            setIsLoading(false);

            handleLog({ status: "disconnected" });

            setTimeout(() => joinHub(), 2000);
          });

          conn.on(userId, (message) => {
            handleLog(message);
          });

          await conn.start().then(() => {
            setConnected(true);
            setIsLoading(false);

            handleLog({ status: "connected" });
          });

          setConnection(conn);
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      handleLog({ status: "Could not obtain API token..." });
    }
  };

  const closeConnection = async () => {
    setIsLoading(true);
    try {
      await connection.stop();

      setUserLog([]);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="SignalRTest">
      <Card>
        <CardHeader tag="h5">SignalR Test</CardHeader>
        <CardBody>
          {!connected && (
            <>
              <CardText>
                <b>User Id</b>
              </CardText>
              <Input
                className="mb-1rem"
                type="text"
                name="userId"
                id="userId"
                value={userId}
                disabled={isLoading}
                onChange={(e) => setUserId(e.target.value)}
              />
              <Button
                color="success"
                size="sm"
                disabled={isLoading}
                onClick={() => joinHub()}
              >
                Connect to the Crews hub{" "}
                {isLoading && <Spinner size="sm" color="primary" />}
              </Button>
            </>
          )}
          {connected && (
            <>
              <CardText>
                <b>Listening for: {userId}</b>
              </CardText>
              <Button
                color="danger"
                size="sm"
                disabled={isLoading}
                onClick={() => closeConnection()}
              >
                Close connection{" "}
                {isLoading && <Spinner size="sm" color="primary" />}
              </Button>
            </>
          )}
          {userLog.length !== 0 && (
            <div className="logCards">
              {userLog.map((item, i) => {
                return (
                  <Card className="logCard" key={i}>
                    <CardBody>
                      <Json data={item} />
                    </CardBody>
                    {!item.status && !item.read && (
                      <CardFooter>
                        <Button
                          color="danger"
                          size="sm"
                          id={`read-${i}`}
                          disabled={isLoading}
                          onClick={() => handleRead(item.id)}
                        >
                          Read{" "}
                          {isLoading && <Spinner size="sm" color="primary" />}
                        </Button>
                      </CardFooter>
                    )}
                  </Card>
                );
              })}
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default SignalRTest;
