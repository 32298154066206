import React from "react";
import MailTest from "../components/MailTest";
import "./Mail.css";

const Mail = (props) => {
  return (
    <div className="Mail">
      <div className="container">
        <MailTest {...props} />
      </div>
    </div>
  );
};

export default Mail;
