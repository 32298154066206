import axios from "axios";

const baseUrl = process.env.REACT_APP_APPREG_APIBASEURL;
const hostKey = process.env.REACT_APP_AZFUNCTIONS_HOSTKEY;

const bulkGet = async (doc, token = null) => {
  const url = `${baseUrl}/api/bulkGet?code=${hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const bulkInsert = async (doc, token = null) => {
  const url = `${baseUrl}/api/bulkInsert?code=${hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const bulkUpdate = async (doc, token = null) => {
  const url = `${baseUrl}/api/bulkUpdate?code=${hostKey}`;
  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

const bulkDelete = async (doc, token = null) => {
  const url = `${baseUrl}/api/bulkDelete?code=${hostKey}`;

  const { data, status } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: doc,
    validateStatus: (status) => {
      return status < 501;
    }
  });

  return { status, response: data };
};

export { bulkGet, bulkInsert, bulkUpdate, bulkDelete };
