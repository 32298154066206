const VERSION = "1.0.0";
const parseAsTrue = ["yes", "true", "1"];
const parseAsFalse = ["no", "false", "0"];

const fieldDefinitions = {
  id: {
    description:
      'There are two variations of "ids" depending on the type of crew member',
    variations: {
      badged: {
        required: true,
        description: "The badge Id of a DTE employee",
        examples: "U12345, U12345678",
        type: "string",
        regex: "^[A-Z0-9]*$",
        minLength: 6,
        maxLength: 64,
        transform: {
          uppperCase: true
        }
      },
      foreign: {
        required: true,
        description: "The email address of a contractor with no DTE badge Id",
        examples: "john.doe@doe.com",
        type: "string",
        regex:
          "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
        minLength: 6,
        maxLength: 64,
        transform: {
          lowerCase: true
        }
      }
    }
  },
  type: {
    required: true,
    description: null,
    examples: null,
    type: "string",
    options: ["badged", "foreign"],
    transform: {
      lowerCase: true
    }
  },
  name: {
    required: true,
    description: null,
    examples: null,
    type: "string",
    transform: {
      uppperCase: true
    }
  },
  org: {
    required: false,
    description: null,
    examples: null,
    type: "string",
    transform: {
      default: "",
      uppperCase: true
    }
  },
  crew: {
    required: false,
    description: null,
    examples: null,
    type: "array",
    transform: {
      default: "",
      uppperCase: true,
      split: { delimiter: "," }
    }
  },
  serviceCenter: {
    required: false,
    description: null,
    examples: null,
    type: "array",
    transform: {
      default: "",
      uppperCase: true,
      split: { delimiter: "," }
    }
  },
  supervisor: {
    required: false,
    description: null,
    examples: null,
    type: "boolean",
    transform: {
      default: false,
      true: parseAsTrue,
      false: parseAsFalse
    }
  },
  tag: {
    required: false,
    description: null,
    examples: null,
    type: "string",
    transform: {
      default: "",
      lowerCase: true
    }
  },
  treeTrim: {
    required: false,
    description: null,
    examples: null,
    type: "boolean",
    transform: {
      default: false,
      true: parseAsTrue,
      false: parseAsFalse
    }
  },
  damageAssessment: {
    required: false,
    description: null,
    examples: null,
    type: "boolean",
    transform: {
      default: false,
      true: parseAsTrue,
      false: parseAsFalse
    }
  },
  countryCode: {
    required: true,
    description: null,
    examples: null,
    type: "string",
    options: ["1"],
    length: 1,
    transform: {
      default: "1"
    }
  },
  phoneNumber: {
    required: false,
    description: null,
    examples: null,
    type: "string",
    overrideType: "object",
    length: 10,
    transform: {
      default: null
    }
  },
  active: {
    required: false,
    description: null,
    examples: null,
    type: "boolean",
    transform: {
      default: true,
      true: parseAsTrue,
      false: parseAsFalse
    }
  }
};

const importTemplateHeaders = [
  "id",
  "type",
  "name",
  "org",
  "crew",
  "serviceCenter",
  "active",
  "tag",
  "supervisor",
  "treeTrim",
  "damageAssessment",
  "countryCode",
  "phoneNumber"
];

const deleteTemplateHeaders = ["id", "type", "name"];

const getVersion = () => {
  return VERSION;
};

const getCrewMemberFieldHeaders = () => {
  return Object.keys(fieldDefinitions);
};

const getCrewMemberTemplateHeaders = (mode) => {
  if (mode === "import") return importTemplateHeaders;
  if (mode === "delete") return deleteTemplateHeaders;

  return null;
};

const getCrewMemberFieldDefinitions = () => {
  return fieldDefinitions;
};

const getCrewMemberFieldDefinition = (field) => {
  return fieldDefinitions[field];
};

export {
  getVersion,
  getCrewMemberFieldHeaders,
  getCrewMemberTemplateHeaders,
  getCrewMemberFieldDefinitions,
  getCrewMemberFieldDefinition
};
