const roles = [
  { role: "admin", label: "Admin" },
  { role: "it-admin", label: "IT Admin" },
  { role: "dev", label: "Developer" },
  { role: "guest", label: "Requested access" }
];

const getUserRoles = () => {
  return roles;
};

export { getUserRoles };
