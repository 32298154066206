import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Collapse,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import {
  getVersion,
  getCrewMemberTemplateHeaders,
  getCrewMemberFieldDefinition
} from "../models/importCrewMember";
import classnames from "classnames";
import prettyBytes from "pretty-bytes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import "./ImportCrewMemberResourcesComp.css";

const VERSION = getVersion();

const ImportCrewMemberResourcesComp = (props) => {
  const { mode } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [collapseIcon, setCollapseIcon] = useState(faChevronDown);

  // Control collapse
  const toggle = () => setCollapse(!collapse);
  const onEntering = () => setCollapseIcon(faChevronUp);
  const onExiting = () => setCollapseIcon(faChevronDown);

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Control download
  const [hrefCsv, setHrefCsv] = useState(null);
  const [csvSize, setCsvSize] = useState(null);

  const downloadFilename =
    mode === "import"
      ? `import-crew-member-data-${VERSION}.csv`
      : mode === "delete"
      ? `delete-crew-member-data-${VERSION}.csv`
      : "";

  const downloadFileLabel =
    mode === "import"
      ? `Import Crew Member Data Template v.${VERSION}`
      : mode === "delete"
      ? `Delete Crew Member Data Template v.${VERSION}`
      : "";

  const primaryColor =
    mode === "import" ? "primary" : mode === "delete" ? "danger" : "default";

  const handleDownload = () => {
    setIsLoading(true);

    // Get headers
    const csvData = getCrewMemberTemplateHeaders(mode);

    // Create URL for CSV data
    const fileCsv = new Blob([csvData.join(",")], {
      type: "text/csv"
    });

    setHrefCsv(URL.createObjectURL(fileCsv));
    setCsvSize(fileCsv.size);

    setIsLoading(false);
  };

  const handleDownloadCancel = () => {
    setHrefCsv(null);
    setCsvSize(null);
  };

  const ShowDictionary = () => {
    const [selectedCol, setSelectedCol] = useState("");
    const cols = getCrewMemberTemplateHeaders(mode);

    const handleCol = (col) => {
      setSelectedCol(col);
    };

    const ShowDefinition = () => {
      const definition = getCrewMemberFieldDefinition(selectedCol);
      const { description } = definition;

      const ShowTransformations = (props) => {
        const { transform } = props;
        const actions = Object.keys(transform);
        const items = [];

        for (let i = 0; i < actions.length; i++) {
          const action = actions[i];

          switch (action) {
            case "uppperCase":
              items.push("Transformed to upper case");
              break;
            case "lowerCase":
              items.push("Transformed to lower case");
              break;
            case "default":
              const defValue = transform[action];
              let label = defValue === null ? "null" : defValue;
              label = label === "" ? "empty string" : label;

              items.push(
                `Defaults to ${label.toString()} when no value is provided`
              );
              break;
            case "split":
              const delimiter = transform[action].delimiter;

              items.push(
                `Transformed to an array from values delimited by "${delimiter}"`
              );
              break;
            case "true":
              const trueList = transform[action];

              items.push(
                `Transformed to true if any of these values is provided: "${trueList.join(
                  '", "'
                )}"`
              );
              break;
            case "false":
              const falseList = transform[action];

              items.push(
                `Transformed to false if any of these values is provided: "${falseList.join(
                  '", "'
                )}"`
              );
              break;
            default:
            // Do nothing
          }
        }

        return (
          <ol>
            {items.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ol>
        );
      };

      const ShowProps = (props) => {
        const { definition } = props;

        const {
          required,
          description,
          examples,
          type,
          options,
          regex,
          length,
          minLength,
          maxLength,
          transform
        } = definition;
        const isBoolean = type === "boolean";

        return (
          <>
            {description && (
              <CardText>
                <b>Description: </b>
                <br />
                {description}
              </CardText>
            )}
            <CardText>
              <b>Required: </b>
              {required ? "Yes" : "No"}
            </CardText>
            <CardText>
              <b>Data type: </b>
              {type}
            </CardText>
            {options && (
              <CardText>
                <b>Acceptable values: </b>
                {`"${options.join('", "')}"`}
              </CardText>
            )}
            {isBoolean && (
              <>
                <CardText>
                  <b>Truthy values: </b>
                  {`"${transform.true.join('", "')}"`}
                </CardText>
                <CardText>
                  <b>Falsy values: </b>
                  {`"${transform.false.join('", "')}"`}
                </CardText>
              </>
            )}
            {regex && (
              <CardText>
                <b>Pattern (RE): </b>
                <br />
                <code>/{regex}/</code>
              </CardText>
            )}
            {length && (
              <CardText>
                <b>Fixed length: </b>
                {length} character(s)
              </CardText>
            )}
            {minLength && (
              <CardText>
                <b>Minimum length: </b>
                {minLength} character(s)
              </CardText>
            )}
            {maxLength && (
              <CardText>
                <b>Maximum length: </b>
                {maxLength} character(s)
              </CardText>
            )}
            {examples && (
              <CardText>
                <b>Examples: </b>
                <br />
                <i>{examples}</i>
              </CardText>
            )}
            {transform && (
              <>
                <CardText>
                  <b>Transformations</b>
                  <br />
                  <small>Transformations happen in order of appearance</small>
                </CardText>
                <ShowTransformations transform={{ ...transform }} />
              </>
            )}
          </>
        );
      };

      return (
        <Card>
          <CardHeader className={`bg-${primaryColor} text-white`}>
            {selectedCol}
          </CardHeader>
          <CardBody>
            <CardText>
              <b>Column name: </b>
              <Badge color={primaryColor}>
                <b>{selectedCol}</b>
              </Badge>
            </CardText>
            {selectedCol !== "id" && (
              <ShowProps definition={{ ...definition }} />
            )}
            {selectedCol === "id" && description && (
              <CardText>
                <b>Description: </b>
                <br />
                {description}
              </CardText>
            )}
            {selectedCol === "id" &&
              Object.keys(definition.variations).map((variation, i) => {
                return (
                  <Card key={i} className="mb-1rem">
                    <CardHeader>{variation}</CardHeader>
                    <CardBody>
                      <ShowProps
                        definition={{ ...definition.variations[variation] }}
                      />
                    </CardBody>
                  </Card>
                );
              })}
          </CardBody>
        </Card>
      );
    };

    return (
      <div>
        <CardText>
          <b>Version: </b> {VERSION}
        </CardText>
        <FormGroup>
          <Label for="columns">
            <b>Pick a column</b>
          </Label>
          <Input
            id="columns"
            name="columms"
            type="select"
            value={selectedCol}
            onChange={(e) => handleCol(e.target.value)}
          >
            <option key={0} value="">
              Select column from the list
            </option>
            {cols.map((col, i) => {
              return (
                <option key={i + 1} value={col}>
                  {col}
                </option>
              );
            })}
          </Input>
        </FormGroup>
        {selectedCol !== "" && <ShowDefinition />}
      </div>
    );
  };

  return (
    <div className="ImportCrewMemberResourcesComp">
      <Card className="mb-1rem">
        <CardHeader tag="h6">
          Help & Resources
          <Button
            outline
            color="primary"
            size="sm"
            onClick={toggle}
            className="collapse-button"
          >
            <FontAwesomeIcon icon={collapseIcon} />
          </Button>
        </CardHeader>
        <Collapse
          isOpen={collapse}
          onEntering={onEntering}
          onExiting={onExiting}
        >
          <CardBody>
            <Nav tabs className="top-nav">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1"
                  })}
                  onClick={() => {
                    toggleTabs("1");
                  }}
                  style={{
                    marginLeft: "0.5rem"
                  }}
                >
                  Data dictionary
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2"
                  })}
                  onClick={() => {
                    toggleTabs("2");
                  }}
                >
                  Data template
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Card className="mt-1rem">
                  <CardBody>
                    <ShowDictionary />
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <Card className="mt-1rem">
                  <CardBody>
                    <CardText>
                      <b>Version: </b> {VERSION}
                    </CardText>
                    <FormGroup className="mb-0rem">
                      {!hrefCsv && (
                        <div>
                          <Button
                            color={primaryColor}
                            size="sm"
                            disabled={isLoading}
                            onClick={handleDownload}
                          >
                            Prepare download
                          </Button>
                        </div>
                      )}
                      {hrefCsv && (
                        <>
                          <CardText>
                            Click on the link below to download the CSV file
                            template
                          </CardText>
                          <CardText>
                            <a href={hrefCsv} download={downloadFilename}>
                              <FontAwesomeIcon icon={faDownload} />{" "}
                              {downloadFileLabel} (
                              {prettyBytes(csvSize ? csvSize : 0)})
                            </a>
                          </CardText>
                          <Button
                            outline
                            color={primaryColor}
                            size="sm"
                            onClick={handleDownloadCancel}
                          >
                            Done
                          </Button>
                        </>
                      )}
                    </FormGroup>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
};

export default ImportCrewMemberResourcesComp;
