import React from "react";
import CrewMemberList from "../components/CrewMemberList";
import "./CrewMembers.css";

const CrewMembers = (props) => {
  return (
    <div className="CrewMembers">
      <div className="container">
        <CrewMemberList {...props} />
      </div>
    </div>
  );
};

export default CrewMembers;
