import axios from "axios";

const baseUrl = process.env.REACT_APP_APPREG_APIBASEURL;
const hostKey = process.env.REACT_APP_AZFUNCTIONS_HOSTKEY;

const getAdminMailPrefs = async (token = null) => {
  const apiEndpoint = `${baseUrl}/api/userAdminMailPrefsGet?code=${hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: apiEndpoint,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return data;
};

export { getAdminMailPrefs };
