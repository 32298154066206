import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Collapse,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane
} from "reactstrap";
import CrewMember from "./CrewMember";
import {
  getCrewMember,
  getCrewMembers,
  createCrewMember
} from "../services/crewMemberService";
import { getCollectionCount } from "../services/collectionService";
import GetPaginationDownload from "./GetPaginationDownload";
import { ModalDownload } from "./ModalDownload";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./CrewMemberList.css";

const searchOptions = [
  { option: "all", label: "All", type: "text" },
  { option: "id", label: "By User Id", type: "text" },
  {
    option: "type",
    label: "By user type",
    type: "check",
    labels: ["Badged", "Foreign"],
    values: ["badged", "foreign"]
  },
  { option: "name", label: "By Name", type: "text" },
  { option: "org", label: "By Organization", type: "text" },
  { option: "crew", label: "By Crew", type: "text" },
  {
    option: "serviceCenter",
    label: "By Service Center",
    type: "text"
  },
  {
    option: "active",
    label: "Is Active",
    type: "check",
    labels: ["Yes", "No"],
    values: [true, false]
  }
];

const getTimestamp = () => new Date().getTime();

const CrewMemberList = (props) => {
  const { user: systemUser, auth } = props;

  const systemUserInfo = {
    id: systemUser.id,
    name: systemUser.name,
    org: systemUser.org,
    email: systemUser.email
  };

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const [searchOption, setSearchOption] = useState("all");
  const [searchOptionValue, setSearchOptionValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const [users, setUsers] = useState([]);

  const newSearch = () => {
    setIsLoading(false);

    setCurrentPage(1);
    setUsers([]);
    toggleSearch();
  };

  // Control pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersCount, setUsersCount] = useState(0);

  const totalUsersPerPage = 20;

  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setUsers([]);

    listUsers(pageNumber, totalUsersPerPage);
  };

  const incChange = (pageNumber, inc, isposOrneg) => {
    let finalPage;
    const residual = pageNumber % inc;
    const control = residual === 0 ? inc : residual;

    if (isposOrneg > 0) {
      finalPage = pageNumber + inc - control + 1;
    } else {
      finalPage = pageNumber - inc - control + 1;
    }

    setCurrentPage(finalPage);
    setUsers([]);

    listUsers(finalPage, totalUsersPerPage);
  };

  const firstChange = () => {
    let finalPage = 1;

    setCurrentPage(finalPage);
    setUsers([]);

    listUsers(finalPage, totalUsersPerPage);
  };

  const lastChange = (finalPage) => {
    setCurrentPage(finalPage);
    setUsers([]);

    listUsers(finalPage, totalUsersPerPage);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const CustomModal = () => {
    const closeBtn = (
      <button className="close" onClick={toggleModal}>
        &times;
      </button>
    );

    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const listUsers = async (page, count) => {
    let res = [];
    let query = {};

    if (searchOption !== "all") query[searchOption] = searchOptionValue;

    try {
      const resUsersCount = await getCollectionCount(
        {
          collection: "CrewMembers",
          ...query
        },
        auth.idToken
      );

      setUsersCount(resUsersCount.count);
    } catch (e) {}

    if (page) query.page = page;
    if (count) query.count = count;

    try {
      res = await getCrewMembers(query, auth.idToken);
      setUsers(res);
    } catch (e) {}

    return res.length;
  };

  const validateForm = () => {
    const isAll = searchOption === "all";

    if (isAll) return true;

    return searchOptionValue !== "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const numUsers = await listUsers(1, totalUsersPerPage);

      if (numUsers) {
        toggleSearch();
      } else {
        showCustomModal({
          title: "Search results",
          message: "No results were found following that criteria"
        });
      }
    } catch (e) {}

    setIsLoading(false);
  };

  const handleSearchOption = (option) => {
    setSearchOption(option);
    setSearchOptionValue("");
  };

  const SearchResults = () => {
    let numberOfPages = 0;
    if (usersCount % totalUsersPerPage === 0) {
      numberOfPages = Math.floor(usersCount / totalUsersPerPage);
    } else {
      numberOfPages = Math.floor(usersCount / totalUsersPerPage) + 1;
    }

    const handleDownload = () => {
      setOpenDownloadModal(true);
    };

    return users.length ? (
      <>
        <div className="NewSearch">
          <Button outline onClick={newSearch} color="secondary">
            New Search
          </Button>
        </div>
        <h5 className="card-title">Search results</h5>
        <GetPaginationDownload
          pages={numberOfPages}
          nextPage={nextPage}
          currentPage={currentPage}
          firstChange={firstChange}
          lastChange={lastChange}
          incChange={incChange}
          handleDownload={handleDownload}
        />
        <div className="UserCards">
          {users.map((o, i) => (
            <CrewMember
              key={i}
              selectedUser={o}
              systemUserInfo={systemUserInfo}
              auth={auth}
            />
          ))}
        </div>
      </>
    ) : (
      " "
    );
  };

  const NewCrewMember = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [inserted, setInserted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [userIdVerified, setUserIdVerified] = useState(false);

    const [userType, setUserType] = useState("badged");
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [org, setOrg] = useState("");
    const [crew, setCrew] = useState([]);
    const [serviceCenter, setServiceCenter] = useState([]);
    const [active, setActive] = useState(true);

    const [tag, setTag] = useState("");
    const [supervisor, setSupervisor] = useState(false);
    const [treeTrim, setTreeTrim] = useState(false);
    const [damageAssessment, setDamageAssessment] = useState(false);

    const formFeedbackInit = {
      userName: {
        invalid: false,
        msg: ""
      },
      phoneNumber: {
        invalid: false,
        msg: ""
      },
      userId: {
        invalid: false,
        msg: ""
      },
      org: {
        invalid: false,
        msg: ""
      },
      crew: {
        invalid: false,
        msg: ""
      },
      serviceCenter: {
        invalid: false,
        msg: ""
      }
    };
    const [formFeedback, setFormFeedback] = useState(formFeedbackInit);

    const showError = (field, invalid, msg) => {
      const copy = { ...formFeedback };

      copy[field].invalid = invalid;
      copy[field].msg = msg;

      setFormFeedback(copy);
      setIsError(invalid);
    };

    const validateInput = () => {
      if (!validateUserId(userId)) return false;

      if (!validateUserName(userName)) return false;

      if (!validatePhoneNumber(phoneNumber)) return false;

      if (!validateOrg(org)) return false;

      if (!validateCrew(crew.join(","))) return false;

      if (!validateServiceCenter(serviceCenter.join(","))) return false;

      return true;
    };

    const handleSave = async () => {
      if (!validateInput()) return false;

      setIsLoading(true);

      const timestamp = getTimestamp();

      const doc = {
        id: userId,
        type: userType,
        name: userName,
        org,
        crew: crew.filter((n) => n),
        serviceCenter: serviceCenter.filter((n) => n),
        options: { supervisor, tag, treeTrim, damageAssessment },
        smsReceivers: {
          countryCode: "1",
          phoneNumber: phoneNumber.length === 0 ? null : phoneNumber
        },
        active,
        createdBy: systemUserInfo,
        createdAt: timestamp
      };

      await createCrewMember(doc, auth.idToken).then(() => {
        setIsLoading(false);
        setInserted(true);
      });
    };

    const handleReset = () => {
      setUserId("");
      setUserName("");
      setPhoneNumber("");
      setOrg("");
      setCrew([]);
      setServiceCenter([]);
      setTag("");
      setSupervisor(false);
      setActive(true);
      setTreeTrim(false);
      setDamageAssessment(false);
      setFormFeedback(formFeedbackInit);
      setInserted(false);
      setUserIdVerified(false);
    };

    const validateUserId = (str) => {
      let status = true;

      switch (userType) {
        case "badged":
          if (str === "" && userIdVerified) {
            showError("userId", true, "Crew member user Id can't be empty");

            status = false;
          } else {
            showError("userId", false, "");

            if (str.length < 6) {
              showError(
                "userId",
                true,
                "Crew member user Id must be at least 6 characters long"
              );

              status = false;
            } else {
              if (str.length > 64) {
                showError(
                  "userId",
                  true,
                  "Crew member user Id must be at most 64 characters long"
                );

                status = false;
              } else {
                if (!/^[A-Z0-9]*$/.test(str)) {
                  showError(
                    "userId",
                    true,
                    "Invalid character(s) in crew member id"
                  );

                  status = false;
                }
              }
            }
          }
          break;
        case "foreign":
          if (str === "" && userIdVerified) {
            showError("userId", true, "Crew member user Id can't be empty");

            status = false;
          } else {
            showError("userId", false, "");

            if (str.length < 6) {
              showError(
                "userId",
                true,
                "Crew member user Id must be at least 6 characters long"
              );

              status = false;
            } else {
              if (str.length > 64) {
                showError(
                  "userId",
                  true,
                  "Crew member user Id must be at most 64 characters long"
                );

                status = false;
              } else {
                if (
                  !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                    str
                  )
                ) {
                  showError(
                    "userId",
                    true,
                    "Invalid character(s) in crew member id"
                  );

                  status = false;
                }
              }
            }
          }
          break;
        default:
        // Do nothing
      }

      return status;
    };

    const handleUserBadgeId = (str) => {
      const newStr = str.toUpperCase();

      validateUserId(newStr);

      setUserId(newStr);
    };

    const handleForeignUserId = (str) => {
      const newStr = str.toLowerCase();

      validateUserId(newStr);

      setUserId(newStr);
    };

    const validateUserName = (str) => {
      let status = true;

      if (str === "") {
        showError("userName", true, "Member name can't be empty");

        status = false;
      } else {
        showError("userName", false, "");

        if (str.length < 3) {
          showError(
            "userName",
            true,
            "Name must be at least 3 characters long"
          );

          status = false;
        } else {
          if (str.length > 64) {
            showError(
              "userName",
              true,
              "Name must be at most 64 characters long"
            );

            status = false;
          } else {
            if (!/^[A-Za-z \-']*$/.test(str)) {
              showError("userName", true, "Invalid character(s) in name");

              status = false;
            }
          }
        }
      }

      return status;
    };

    const handleUserName = (str) => {
      const newStr = str.toUpperCase();

      validateUserName(newStr);

      setUserName(newStr);
    };

    const validatePhoneNumber = (str) => {
      let status = true;

      if (str !== "") {
        showError("phoneNumber", false, "");

        if (str.length < 10) {
          showError(
            "phoneNumber",
            true,
            "Phone number must be 10 characters long"
          );

          status = false;
        } else {
          if (str.length > 10) {
            showError(
              "phoneNumber",
              true,
              "Phone number must be 10 characters long"
            );

            status = false;
          } else {
            if (!/^[0-9]{10}$/.test(str)) {
              showError(
                "phoneNumber",
                true,
                "Invalid character(s) in phone number"
              );

              status = false;
            }
          }
        }
      } else {
        showError("phoneNumber", false, "");
      }

      return status;
    };

    const handlePhoneNumber = (str) => {
      validatePhoneNumber(str);

      setPhoneNumber(str);
    };

    const validateOrg = (str) => {
      let status = true;

      if (str.length > 0) {
        showError("org", false, "");

        if (str.length < 3) {
          showError(
            "org",
            true,
            "If provided, organization must be at least 3 characters long"
          );

          status = false;
        } else {
          if (str.length > 64) {
            showError(
              "org",
              true,
              "If provided, organization must be at most 64 characters long"
            );

            status = false;
          } else {
            if (!/^[A-Za-z0-9 \-']*$/.test(str)) {
              showError("org", true, "Invalid character(s) in organization");

              status = false;
            }
          }
        }
      } else {
        showError("org", false, "");
      }

      return status;
    };

    const handleOrg = (str) => {
      const newStr = str.toUpperCase();

      validateOrg(newStr);

      setOrg(newStr);
    };

    const validateCrew = (str) => {
      let status = true;

      if (str.length > 0) {
        showError("crew", false, "");

        if (str.length < 2) {
          showError(
            "crew",
            true,
            "Crew list must be at least 2 characters long"
          );

          status = false;
        } else {
          if (!/^[A-Z0-9,']*$/.test(str)) {
            showError(
              "crew",
              true,
              "Invalid character(s) in crew list. Uppercase letters or numbers only. Add comma for multiple crews."
            );

            status = false;
          }
        }
      } else {
        showError("crew", false, "");
      }

      return status;
    };

    const handleCrew = (str) => {
      const newStr = str.toUpperCase();

      validateCrew(newStr);

      const arr = newStr.split(",").map((o) => o.trim());
      setCrew(arr);
    };

    const validateServiceCenter = (str) => {
      let status = true;

      if (str.length > 0) {
        showError("serviceCenter", false, "");

        if (str.length < 2) {
          showError(
            "serviceCenter",
            true,
            "Service center list must be at least 2 characters long"
          );

          status = false;
        } else {
          if (!/^[A-Z0-9,']*$/.test(str)) {
            showError(
              "serviceCenter",
              true,
              "Invalid character(s) in service center list. Uppercase letters or numbers only. Add comma for multiple service centers."
            );

            status = false;
          }
        }
      } else {
        showError("serviceCenter", false, "");
      }

      return status;
    };

    const handleServiceCenter = (str) => {
      const newStr = str.toUpperCase();

      validateServiceCenter(newStr);

      const arr = newStr.split(",").map((o) => o.trim());
      setServiceCenter(arr);
    };

    const handleSupervisor = (option) => {
      setSupervisor(option);
    };

    const handleActive = (option) => {
      setActive(option);
    };

    const handleTag = (option) => {
      setTag(option);
    };

    const handleTreeTrim = (option) => {
      setTreeTrim(option);
    };

    const handleDamageAssessment = (option) => {
      setDamageAssessment(option);
    };

    const handleUserIdSearch = async () => {
      if (userId === "") {
        showError("userId", true, "Crew member user Id can't be empty");
      } else {
        showError("userId", false, "");

        setIsLoading(true);

        await getCrewMember(userId, auth.idToken).then((res) => {
          if (res) {
            showError("userId", true, "Crew member user Id already exists");
          } else {
            showError("userId", false, "");
            setUserIdVerified(true);
          }

          setIsLoading(false);
        });
      }
    };

    const handleUserType = (type) => {
      setUserType(type);
      handleReset();
    };

    return (
      <Card className="mt-1rem">
        {!inserted && (
          <>
            <CardBody>
              <FormGroup tag="fieldset">
                <Card>
                  <CardHeader>Crew Member Info</CardHeader>
                  <CardBody>
                    <Label for="userType">
                      <b>Select user type:</b>
                    </Label>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="userType"
                          value={"badged"}
                          defaultChecked={userType === "badged"}
                          onChange={(e) => handleUserType(e.target.value)}
                        />{" "}
                        Badged
                      </Label>
                    </FormGroup>
                    <FormGroup check className="mb-05rem">
                      <Label check>
                        <Input
                          type="radio"
                          name="userType"
                          value={"foreign"}
                          defaultChecked={userType === "foreign"}
                          onChange={(e) => handleUserType(e.target.value)}
                        />{" "}
                        Foreign
                      </Label>
                    </FormGroup>
                    {userType === "badged" && (
                      <>
                        <CardText className="mb-05rem">
                          <b>
                            User badge Id <sup>*</sup>:
                          </b>
                        </CardText>
                        <FormGroup className="input-group">
                          <Input
                            invalid={formFeedback.userId.invalid}
                            type="text"
                            name="userId"
                            id="userId"
                            disabled={isLoading || userIdVerified}
                            placeholder={"Enter user Id for verification"}
                            value={userId}
                            onChange={(e) => handleUserBadgeId(e.target.value)}
                          />
                          <span className="input-group-append">
                            <Button
                              outline
                              className="btn btn-outline-secondary"
                              disabled={isLoading || userIdVerified || isError}
                              onClick={() => handleUserIdSearch()}
                            >
                              <FontAwesomeIcon icon={faSearch} />
                            </Button>
                          </span>
                          <FormFeedback>{formFeedback.userId.msg}</FormFeedback>
                        </FormGroup>
                      </>
                    )}
                    {userType === "foreign" && (
                      <>
                        <CardText className="mb-05rem">
                          <b>
                            User email <sup>*</sup>:
                          </b>
                        </CardText>
                        <FormGroup className="input-group">
                          <Input
                            invalid={formFeedback.userId.invalid}
                            type="text"
                            name="userId"
                            id="userId"
                            disabled={isLoading || userIdVerified}
                            placeholder={"Enter user email for verification"}
                            value={userId}
                            onChange={(e) =>
                              handleForeignUserId(e.target.value)
                            }
                          />
                          <span className="input-group-append">
                            <Button
                              outline
                              className="btn btn-outline-secondary"
                              disabled={isLoading || userIdVerified || isError}
                              onClick={() => handleUserIdSearch()}
                            >
                              <FontAwesomeIcon icon={faSearch} />
                            </Button>
                          </span>
                          <FormFeedback>{formFeedback.userId.msg}</FormFeedback>
                        </FormGroup>
                      </>
                    )}
                    {userIdVerified && (
                      <div>
                        <FormGroup>
                          <Label for="userName">
                            <b>
                              Name <sup>*</sup>:
                            </b>
                          </Label>
                          <Input
                            invalid={formFeedback.userName.invalid}
                            type="text"
                            name="userName"
                            id="userName"
                            disabled={isLoading}
                            placeholder={""}
                            value={userName}
                            onChange={(e) => handleUserName(e.target.value)}
                          />
                          <FormFeedback>
                            {formFeedback.userName.msg}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="phoneNumber">
                            <b>Phone number:</b>
                          </Label>
                          <InputGroup>
                            <InputGroupText>+1</InputGroupText>
                            <Input
                              invalid={formFeedback.phoneNumber.invalid}
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              disabled={isLoading}
                              placeholder={
                                "Enter 10 digits including area code"
                              }
                              value={phoneNumber}
                              onChange={(e) =>
                                handlePhoneNumber(e.target.value)
                              }
                            />
                            <FormFeedback>
                              {formFeedback.phoneNumber.msg}
                            </FormFeedback>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="org">
                            <b>Organization:</b>
                          </Label>
                          <Input
                            invalid={formFeedback.org.invalid}
                            type="text"
                            name="org"
                            id="org"
                            disabled={isLoading}
                            placeholder={""}
                            value={org}
                            onChange={(e) => handleOrg(e.target.value)}
                          />
                          <FormFeedback>{formFeedback.org.msg}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="crew">
                            <b>Crew(s):</b>
                          </Label>
                          <Input
                            invalid={formFeedback.crew.invalid}
                            type="text"
                            name="crew"
                            id="crew"
                            disabled={isLoading}
                            placeholder={"Comma-delimited string"}
                            value={crew.join(",")}
                            onChange={(e) => handleCrew(e.target.value)}
                          />
                          <FormFeedback>{formFeedback.crew.msg}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="serviceCenter">
                            <b>Service center:</b>
                          </Label>
                          <Input
                            invalid={formFeedback.serviceCenter.invalid}
                            type="text"
                            name="serviceCenter"
                            id="serviceCenter"
                            disabled={isLoading}
                            placeholder={"Comma-delimited string"}
                            value={serviceCenter.join(",")}
                            onChange={(e) =>
                              handleServiceCenter(e.target.value)
                            }
                          />
                          <FormFeedback>
                            {formFeedback.serviceCenter.msg}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="active">
                            <b>Active:</b>
                          </Label>
                          <CustomInput
                            type="switch"
                            name="active"
                            label={active ? "Yes" : "No"}
                            id="active"
                            disabled={isLoading}
                            checked={active}
                            onChange={(e) => handleActive(e.target.checked)}
                          />
                        </FormGroup>
                      </div>
                    )}
                  </CardBody>
                  <CardFooter>
                    <CardText>
                      <sup>*</sup> Required field
                    </CardText>
                  </CardFooter>
                </Card>
                {userIdVerified && (
                  <Card className="mt-1rem">
                    <CardHeader>Overrides</CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label for="tag">
                          <b>Tag</b>
                        </Label>
                        <CustomInput
                          type="select"
                          id="tag"
                          name="tag"
                          disabled={isLoading}
                          value={tag}
                          onChange={(e) => handleTag(e.target.value)}
                        >
                          <option value="">Select a tag</option>
                          <option value="employee">Employee</option>
                          <option value="contractor">Contractor</option>
                        </CustomInput>
                      </FormGroup>
                      <FormGroup>
                        <CustomInput
                          type="switch"
                          name="supervisor"
                          label="Supervisor"
                          id="supervisor"
                          disabled={isLoading}
                          checked={supervisor}
                          onChange={(e) => handleSupervisor(e.target.checked)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <CustomInput
                          type="switch"
                          name="treeTrim"
                          label="Tree trim"
                          id="treeTrim"
                          disabled={isLoading}
                          checked={treeTrim}
                          onChange={(e) => handleTreeTrim(e.target.checked)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <CustomInput
                          type="switch"
                          name="damageAssessment"
                          label="Damage Assessment"
                          id="damageAssessment"
                          disabled={isLoading}
                          checked={damageAssessment}
                          onChange={(e) =>
                            handleDamageAssessment(e.target.checked)
                          }
                        />
                      </FormGroup>
                    </CardBody>
                  </Card>
                )}
              </FormGroup>
            </CardBody>
            {userIdVerified && (
              <CardFooter>
                <Button
                  color="primary"
                  size="sm"
                  disabled={isError || isLoading}
                  onClick={handleSave}
                >
                  Save {isLoading && <Spinner size="sm" color="light" />}
                </Button>
                <Button
                  className="ml-025rem"
                  color="default"
                  size="sm"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </CardFooter>
            )}
          </>
        )}
        {inserted && (
          <CardBody>
            <CardText>Crew member was successfully added...</CardText>
            <Button color="primary" size="sm" onClick={handleReset}>
              Continue
            </Button>
          </CardBody>
        )}
      </Card>
    );
  };

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");

  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="CrewMemberList">
      <Card>
        <CardHeader tag="h5">Crew Member Management</CardHeader>
        <CardBody>
          <Collapse isOpen={isSearchOpen}>
            <Nav tabs className="top-nav">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1"
                  })}
                  onClick={() => {
                    toggleTabs("1");
                  }}
                  style={{ marginLeft: "0.5rem" }}
                >
                  Search
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2"
                  })}
                  onClick={() => {
                    toggleTabs("2");
                  }}
                >
                  Add Crew Member
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Card className="mt-1rem">
                  <CardBody>
                    <Form onSubmit={handleSubmit} id="search-form">
                      <FormGroup tag="fieldset">
                        <h5 className="card-title">
                          Select the option you will be searching by
                        </h5>
                        {searchOptions.map((el, i) => {
                          const isAll = el.option === "all";
                          const isText = el.type === "text";
                          const isCheck = el.type === "check";
                          const matchedOption = el.option === searchOption;
                          const showInput = !isAll && matchedOption;
                          const placeholders = {
                            id: "Type the close match",
                            name: "Type a close match",
                            org: "Type a close match",
                            crew: "Type the close match",
                            serviceCenter: "Type a close match"
                          };

                          return (
                            <div key={i}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="role"
                                    value={el.option}
                                    defaultChecked={el.option === "all"}
                                    onChange={(e) =>
                                      handleSearchOption(e.target.value)
                                    }
                                  />{" "}
                                  {el.label}
                                </Label>
                              </FormGroup>
                              {showInput && isText && (
                                <FormGroup className="search-option-value">
                                  <Input
                                    type="text"
                                    name="searchOptionValue"
                                    id="searchOptionValue"
                                    placeholder={placeholders[el.option ?? ""]}
                                    value={searchOptionValue}
                                    onChange={(e) =>
                                      setSearchOptionValue(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              )}
                              {showInput && isCheck && (
                                <FormGroup
                                  className="search-option-value ml-1rem"
                                  check
                                >
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="searchOptionValue"
                                      id="searchOptionValue"
                                      value={el.values[0]}
                                      onChange={(e) =>
                                        setSearchOptionValue(e.target.value)
                                      }
                                    />{" "}
                                    {el.labels[0]}
                                  </Label>{" "}
                                  <Label check className="ml-15rem">
                                    <Input
                                      type="radio"
                                      name="searchOptionValue"
                                      id="searchOptionValue"
                                      value={el.values[1]}
                                      onChange={(e) =>
                                        setSearchOptionValue(e.target.value)
                                      }
                                    />{" "}
                                    {el.labels[1]}
                                  </Label>
                                </FormGroup>
                              )}
                            </div>
                          );
                        })}
                      </FormGroup>
                      <Button
                        className="btn-lg"
                        disabled={!validateForm() || isLoading}
                        color="primary"
                        block
                      >
                        Search {isLoading && <Spinner color="light" />}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <NewCrewMember />
              </TabPane>
            </TabContent>
          </Collapse>
          <SearchResults />
        </CardBody>
      </Card>
      <CustomModal />
      {openDownloadModal && (
        <ModalDownload
          searchOption={searchOption}
          searchOptionValue={searchOptionValue}
          currentPage={currentPage}
          pageSize={totalUsersPerPage}
          totalDocs={usersCount}
          handleClose={setOpenDownloadModal}
          auth={auth}
        />
      )}
    </div>
  );
};

export default CrewMemberList;
